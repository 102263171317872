import React from 'react'
import Helmet from 'react-helmet'

import '../css/index.css'

export default ({ children, ...props }) => {
  return (
    <main {...props}>
      <Helmet>
        <meta property="og:site_name" content="Nathan Dunn" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@nthndnn" />
        <meta name="twitter:site" content="@nthndnn" />
      </Helmet>

      {children}
    </main>
  )
}