import React, { useState } from 'react'
import { Link } from 'gatsby'

const Nav = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <nav className="flex items-center justify-between flex-wrap bg-olive p-3" {...props}>
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <span className="font-heading font-bold text-xl text-grey">
          <Link href="/">Nathan Dunn</Link>
        </span>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-grey border-grey hover:text-white hover:border-white" onClick={toggleOpen}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className={isOpen ? `w-full block flex-grow lg:flex lg:items-center lg:w-auto` : `w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden`}>
        <div className="text-sm lg:flex-grow">
          <Link to="/" className="block mt-4 text-grey lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Home
          </Link>
          {/* <Link to="/now" className="block mt-4 text-grey lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Now
          </Link> */}
          <Link to="/talks" className="block mt-4 text-grey lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Talks
          </Link>
          <Link to="/blog" className="block mt-4 text-grey lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
            Blog
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Nav